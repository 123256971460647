import URI from 'urijs'
import { config } from '@/plugins/env.config'
import Vue from 'vue'

class ProvidersApiService {
  async getSponsorProviders( sponsorSlug , attemptNumber = 1) {
    const getSponsorProvidersURI = new URI(config.apiBaseUrl)
    getSponsorProvidersURI.segment('providers')
    getSponsorProvidersURI.segment('v1')
    getSponsorProvidersURI.segment('providers')
    getSponsorProvidersURI.segment('sponsor')
    getSponsorProvidersURI.segment(sponsorSlug)
    getSponsorProvidersURI.segment('providers')

    try {
      return await Vue.prototype.$http.get(getSponsorProvidersURI.toString())
    } catch (error) {
      const maxAttempts = config.getTokenMaxAttempts
      const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

      const response = error.response ? error.response : error
      const status = response.status ? response.status : error.status

      if (status === 500 && attemptNumber < maxAttempts) {
        await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
        
        return this.getSponsorProviders({ sponsorSlug, attemptNumber: attemptNumber + 1 })
      }

      throw error
    }
  }
}

export default new ProvidersApiService();