<template>
  <v-col class="pa-0">
    <v-row>
      <v-col class="col-8 pb-0" >
        <div v-if="!!$slots.title">
          <slot name="title"></slot>
        </div>
        <span v-else class="text-subtitle-1 osg-black--text">{{ field.label }}</span>
      </v-col>
      <v-col class="pb-0 text-right col-4">
        <v-btn
          depressed
          small
          :loading="loadingFile"
          rounded
          @click="onAddFile()"
        >
          <v-icon small color="secondary">mdi-plus</v-icon>
          <span class="text-button secondary--text">
            {{ $t('addAuthorization.buttons.add') }}
          </span>
        </v-btn>
      </v-col>
      <v-col cols="12" class="py-0">
        <span class="text-caption"> 
          {{ $t('addAuthorization.fields.formats', { formats: fileTypes, size: getSize(config.fileSize) }) }} 
        </span>
      </v-col>
      <v-col cols="12" :class="{ 'py-0': !field.required && field.label }" >
        <span class="text-overline" v-if="!field.required && field.label"> {{ $t('addAuthorization.fields.optional') }} </span>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="field.description">
      <v-col class="pb-1">
        <span class="text-body-2 osg-bloack--text">{{ field.description }}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <input
          multiple
          ref="fieldValidationForm"
          counter
          class="d-none"
          type="file"
          hide-details="auto"
          :accept="field.mimeTypes"
          :label="field.label"
          @change="addFilesOnDetail ? onDetailFileChanged($event) : onFileChanged($event)"
        >
        <v-container class="pa-0">
          <v-row v-if="uploadedFiles.length === 0" dense class="osg-empty-container align-content-center">
            <v-col class="text-center" style="color: #4f4f4f;">
              <div class="text-button text-none pb-2 pl-6">
                <v-icon size="40">$osg-file</v-icon>
                {{ $t('addAuthorization.fields.file.empty') }}
              </div>
            </v-col>
          </v-row>
          <v-row v-else class="ma-auto" no-gutters>
            <v-col>
              <DisplayFiles 
              :files="uploadedFiles" 
              source="FORM" 
              :showPreview="showPreview" 
              :addFilesOnDetail="addFilesOnDetail"
              @deleteFile="addFilesOnDetail ? onDetailDeleteFile($event) : onDeleteFile($event)" 
            />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { inputRules, getExtensions, validateFileType, getSizeDescription } from '@/utils'
import { config } from '@/plugins/env.config'
import DisplayFiles from '@/components/v2/files/DisplayFiles.vue'

const authorizationModule = createNamespacedHelpers('authorizationV2');

export default {
  name: "FileField",
  components: { DisplayFiles },
  props: {
    field: {
      type: Object,
      default: () => ({})
    },
    showPreview: {
      type: Boolean,
      default: true
    },
    addFilesOnDetail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: inputRules,
      config
    }
  },
  methods: {
    ...authorizationModule.mapActions(['setError', 'setFiles', 'removeFile', 'setDetailFiles']),
    validate() {
      const formFieldFiles = this.$store.state.authorizationV2.files.filter(stateField => stateField.fieldSlug === this.field.slug);
      if (this.field.required && (!formFieldFiles || formFieldFiles.length === 0)) {
        this.setError({ hasError: true, error: this.$i18n.t('errors.inputValidations.fileRequired') })
        return false;
      }
      if (formFieldFiles && formFieldFiles.length > (this.field.fileLimit || 1)) {
        this.setError({ hasError: true, error: this.$i18n.tc('errors.inputValidations.maxFiles', [this.field.fileLimit || 1]) })
        return false;
      }
      return true;
    },
    onAddFile() {
      this.$refs.fieldValidationForm.click();
    },
     validateFileType(file) {
      return validateFileType(file, this.field)
    },
    getSize(size) {
      return isNaN(size) ?
        size
        : getSizeDescription(parseFloat(size))
    },
    onFileChanged(e) {
      const formFieldFiles = this.files.filter(stateField => stateField.fieldSlug === this.field.slug) || 0;
      const files = e.target.files

      if ((files.length + formFieldFiles.length) > (this.field.fileLimit || 1)) {
          this.setError({ hasError: true, error: this.$tc('errors.inputValidations.maxFiles', [this.field.fileLimit || 1]) })
          this.$refs.fieldValidationForm.value = ''
          return;
        }

      for (let i = 0; i < files.length; i++) {
        const newFile = files[i]

        if (!this.validateFileType(newFile)) {
          this.setError({ hasError: true, error: this.$tc('errors.inputValidations.invalidFile') })
          this.$refs.fieldValidationForm.value = ''
          return;
        }

        const configFileSize = this.config.fileSize
        if (!isNaN(configFileSize)) {
          const allowFileSize = parseFloat(configFileSize)
          if (allowFileSize < newFile.size) {
            this.setError({ hasError: true, error: this.$tc('errors.inputValidations.invalidFileSize') })
            this.$refs.fieldValidationForm.value = ''
            return;
          }
        }
      }

      this.setFiles({ files, fieldSlug: this.field.slug });
      this.setError({ hasError: false, error: null })
      
      this.$refs.fieldValidationForm.value = ''
    },
    onDeleteFile(id) {
      this.removeFile(id);
    },
    onDetailFileChanged(e) {
      const formFieldIndex = this.files.findIndex(stateField => stateField.fieldSlug === this.field.slug);
      const files = this.files;
      const newFile = e.target.files[0]
      if (!this.validateFileType(newFile)) {
        this.setError({hasError: true, error: this.$tc('errors.inputValidations.invalidFile')})
        this.$refs.fieldValidationForm.value = ''
        return;
      }
      
      const configFileSize = this.config.fileSize
      if (!isNaN(configFileSize)) {
        const allowFileSize = parseFloat(configFileSize)
        if (allowFileSize < newFile.size) {
          this.setError({hasError: true, error: this.$tc('errors.inputValidations.invalidFileSize')})
          this.$refs.fieldValidationForm.value = ''
          return;
        }
      }
      if (formFieldIndex < 0) {
        files.push({ fallbackLabel: this.field.label, fieldSlug: this.field.slug, files: [newFile]});
      } else {
        if (files[formFieldIndex].files.length >= (this.field.fileLimit || 1)){
          this.setError({hasError: true, error: this.$tc('errors.inputValidations.maxFiles', [this.field.fileLimit || 1])})
          this.$refs.fieldValidationForm.value = ''
          return;
        }
        const formField = files[formFieldIndex];
        formField.files.push(newFile)
        files[formFieldIndex] = formField
      }
      this.setDetailFiles(files);
      this.setError({hasError: false, error: null})
      this.$refs.fieldValidationForm.value = ''
    },
    onDetailDeleteFile(index) {
      const formFieldIndex = this.files.findIndex(stateField => stateField.fieldSlug === this.field.slug);
      const files = this.files;
      const formField = files[formFieldIndex];
      formField.files.splice(index,1)
      files[formFieldIndex] = formField
      this.setDetailFiles(files);        
    },
  },
  computed: {
    ...authorizationModule.mapGetters(['files', 'loadingFile']),
    uploadedFiles() {
      let files = []
      if (this.addFilesOnDetail) {
        const formField = this.files.find(stateField => stateField.fieldSlug === this.field.slug);
        files = formField && formField.files || [];
      } else {
        files = this.files.filter(stateField => stateField.fieldSlug === this.field.slug) || [];
      }
      return files;
    },
    fileTypes() {
      return getExtensions(this.field.mimeTypes)
    },
    classField() {
      return this.field.cssClass || 'col-12'
    },
  }
}
</script>

<style lang="scss" scoped>
.osg-empty-container {
  width: 100%;
  height: 100px;
  border-radius: 8px;
  margin-left: 0px;
  background-color: #f6f6f6;
}
</style>