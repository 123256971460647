<template>
  <v-col class="pa-0">
    <v-row>
      <v-col class="col-12 pb-0">
        <div v-if="!!$slots.title">
          <slot name="title"></slot>
        </div>
        <span v-else class="text-subtitle-1 osg-black--text">{{ field.label }}</span>
      </v-col>
      
      <v-col cols="12" :class="{'py-0': !field.required && field.label}" >
        <span class="text-overline" v-if="!field.required && field.label"> {{ $t('addAuthorization.fields.optional') }} </span>
      </v-col>
    </v-row>

     <v-row no-gutters v-if="field.description">
      <v-col class="pb-1">
        <span class="text-body-2 osg-bloack--text">{{ field.description }}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row>
          <v-col cols="6" class="py-0">
            <v-select
              :rules="fieldRulesSelect"
              ref="fieldValidationFormCurrency"
              hide-details="auto"
              v-model="currency"
              outlined
              dense
              :label="$t('addAuthorization.fields.currency')"
              item-text="label"
              @change="changeInput"
              item-value="key"
              :items="field.options"
              :hint="field.helpText"
              persistent-hint                
            ></v-select>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field
              ref="fieldValidationForm"
              :rules="[...fieldRules]"
              type="text"
              v-osg-pattern=/^[\d,.]+$/
              v-model="fieldValue"
              outlined
              dense
              :prefix="field.prefix"
              :suffix="field.suffix"
              @input="changeInput"
              hide-details="auto"
              :placeholder="field.placeholder"
              :hint="field.helpText"
              persistent-hint
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>  
    </v-row>
  </v-col>
</template>

<script>
import { inputRules } from '@/utils'

export default {
  name: "CurrencyField",
  props: {
    field: {
      type: Object,
      default: () => ({
        slug: null,
        type: null,
        label: null,
        showOrder: null,
        required: null,
        description: null,
        groupName: null,
        mimeTypes: null,
        fileLimit: null,
        options: null,
        prefix: null,
        suffix: null,
        placeholder: null,
        decimalPrecision: null,
        groupOrder: null,
        cssClass: 'col-12',
        min: null,
        max: null,
        defaultValue: null
      })
    },
    countryCode: null
  },
  mounted() {
    if (this.field.options.length === 1) {
      this.currency =  this.field.options[0].key
    }
  },
  data() {
    return {
      rules: inputRules,
      currency: null,
      fieldValue: null,
    }
  },
  methods: {
    validate() {
      if (!this.$refs.fieldValidationFormCurrency.validate(true) || !this.$refs.fieldValidationForm.validate(true)) {
        return false;
      }
      return true;
    },
    changeInput(e) {
      let value = e;
      value = this.currency || ''; 
      value = value.concat(',', parseFloat(this.fieldValue).toFixed(2) || '');
      this.$emit("input", value);
    },
    getFixedValue(precission) {
      if (this.fieldValue) {
        this.fieldValue = this.formattedAmount(this.fieldValue, precission)
      }
    },
    formattedAmount(amount) {
      return parseFloat(amount.toString().replace(/,/g, ''));
    },
  },
  computed: {
    fieldRules() {
      const fieldRulesArray = []
      if (this.field.required) {
        fieldRulesArray.push(this.rules.required)
      }

      if (this.field.type === 'CURRENCY') {
        fieldRulesArray.push(this.rules.isValidDecimalFormat);
      }

      if(this.field.min === 0 && this.field.max > 0){
        fieldRulesArray.push(this.rules.maxAndMinWithZeroValue(this.field.min, this.field.max))
      }else{
        if (this.field.max) {
          fieldRulesArray.push(this.rules.maxInputValue(this.field.max))
        }
  
        if (this.field.min) {
          fieldRulesArray.push(this.rules.minInputValue(this.field.min))
        }
      }

      return fieldRulesArray;
    },
    fieldRulesSelect() {
      const fieldRulesArray = []
      if (this.field.required) {
        fieldRulesArray.push(this.rules.required)
      }
      return fieldRulesArray
    },
  }
  
}
</script>