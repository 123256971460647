<template>
  <v-row>
    <v-col cols="12"
      class="text-center">
      <v-progress-circular :size="70"
        :width="7"
        color="error"
        indeterminate />
    </v-col>
    <v-col cols="12"
      v-if="message"
      class="text-center">
      <span class="text-h5 secondary--text">{{ message }}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    color: {
      type: String,
      default: 'error',
    },
    message: {
      type: String,
      default: null
    },
  },
};
</script>