<template>
  <v-select
    item-value="key"
    :items="data"
    dense
    outlined
    :rules="[...fieldRules]"
    @change="selectProvider($event)"
    return-object
    item-text="name"
    ref="selectSponsorProviders"
  ></v-select>
</template>

<script>
import { inputRules } from "@/utils";
import { createNamespacedHelpers } from 'vuex'
import ProvidersApiService from '@/services/v2/providers-api-v2.service';

const authorizationModule = createNamespacedHelpers('authorizationV2');
const authModule = createNamespacedHelpers('auth');

export default {
  name: 'SponsorProvidersDropDown',
  props: {
    field: {
      type: Object,
      default: () => {}
    },
    sponsorSlug: {
      type: String
    }
  },
  data() {
    return {
      data: null,
      rules: inputRules,
      providerSlug: ''
    }
  },
  methods: {
    validate() {
      return this.$refs.selectSponsorProviders.validate(true);
    },
    async fetchData() {
      this.providerSlug = await this.getProviderSlug();
      this.data = this.providers;
    },
    selectProvider(event) {
      const provider = {
        providerSlug: event.providerSlug,
        hospitalName: event.name
      }

      const providerJson = JSON.stringify(provider);

      this.$emit('input', providerJson);
    },

    async getProviderSlug(){
      try {
        const sponsorSlug = this.tokenExtensions.sponsorSlug
        const response = await ProvidersApiService.getSponsorProviders(sponsorSlug);
        const result = await response;
        const { data } = result;

        if(data && data.length > 0) {
          const providerSlug = data[0].slug;
          return providerSlug;
        }
        
        return '';

      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    }
  },
  computed: {
    ...authorizationModule.mapGetters(['inputs', 'authorizationForms']),
    ...authModule.mapGetters(['tokenExtensions']),

    fieldRules() {
      const fieldRulesArray = [];

      if (this.field.required) {
        fieldRulesArray.push(this.rules.required)
      }

      return fieldRulesArray;
    },

    providers() {
      const authorizationForm = this.authorizationForms;
      if (authorizationForm && authorizationForm.length > 0) {

        const fields = authorizationForm[0].fields;
        const formDropDown = fields.find(form => form.type ==='SPONSOR_PROVIDERS_DROPDOWN');
        
        if (formDropDown) {
          const options = formDropDown.options;

          const providers = options.map(option => {
            return {
              providerSlug: this.providerSlug,
              name: option.label
            }
          });

          return providers ? providers : [];
        }
      }
      return []
    },
  },
  mounted() {
    this.fetchData();
  }
}
</script>
