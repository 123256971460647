<template>
  <v-col class="pa-0">
    <v-row>
      <v-col class="col-12 pb-0">
        <div v-if="!!$slots.title">
          <slot name="title"></slot>
        </div>
        <span v-else class="text-subtitle-1 osg-black--text">{{
          field.label
        }}</span>
      </v-col>

      <v-col cols="12" :class="{ 'py-0': !field.required && field.label }">
        <span class="text-overline" v-if="!field.required && field.label">
          {{ $t("addAuthorization.fields.optional") }}
        </span>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="field.description">
      <v-col class="pb-1">
        <span class="text-body-2 osg-bloack--text">{{
          field.description
        }}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn-toggle
          style="width:100%"
          v-model="booleanModel"
          color="primary"
          ref="fieldValidationForm"
        >
          <v-btn
            class="col-6"
            :value="option.key"
            v-for="option in field.options"
            :key="option.key"
          >
            <span class="text-body-2">{{ option.label }}</span>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const authorizationModule = createNamespacedHelpers("authorizationV2");

export default {
  name: "BooleanField",
  props: {
    field: {
      type: Object,
      default: () => ({
        slug: null,
        type: null,
        label: null,
        showOrder: null,
        required: null,
        description: null,
        groupName: null,
        mimeTypes: null,
        fileLimit: null,
        options: null,
        prefix: null,
        suffix: null,
        placeholder: null,
        decimalPrecision: null,
        groupOrder: null,
        cssClass: "col-12",
        min: null,
        max: null,
        defaultValue: null,
      }),
    },
  },
  methods: {
    ...authorizationModule.mapActions(["setClaimType", "setInput", 'setError']),
    validate() {
      const inputValue = this.$store.state.authorizationV2.inputs.find(
        (input) => input.slug === this.field.slug
      );
      const value = inputValue ? inputValue.value : null;
      if (value === null) {
        this.setError({
          hasError: true,
          error: this.$i18n.t("errors.inputValidations.ClaimTypeRequired"),
        });
        return false;
      }
      return true;
    },
  },
  computed: {
    ...authorizationModule.mapGetters(["claimType"]),
    booleanModel: {
      get() {
        return this.claimType;
      },
      set(value) {
        this.setClaimType(value);
        this.setInput({ slug: this.field.slug, value: value === "true" });
      },
    },
  },
};
</script>
