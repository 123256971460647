<template>
    <v-row dense>
        <v-col cols="auto">
            <p class="text-body-2 font-weight-bold osg-gray-1--text">{{beneficiary.name}}</p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
            <span class="text-body-2 primary--text font-weight-bold" v-if="beneficiary.owner"> 
                {{ $t('addAuthorization.searchBeneficiary.owner') }}  
            </span>

            <span class="text-body-2 primary--text font-weight-bold" v-else>
                {{ $t('addAuthorization.searchBeneficiary.dependent') }} 
            </span>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'BeneficiaryHeader',
    
    props: {
        beneficiary: {
            type: Object,
            required: true
        },
        active: {
            type: Boolean,
            default: false
        }
    }
}
</script>
