<template>
  <v-container fluid class="pa-0">
    <v-row dense>
      <v-col cols="12" class="pa-0">
        <v-autocomplete :label="searchLabel"
          :hide-no-data="hideNoResults"
          :items="diagnoses"
          :disabled="finderDisabled"
          v-model="selectedDiagnosis"
          :search-input.sync="searchInput"
          item-text="name"
          @change="selectDiagnosis()"
          return-object
          outlined
          dense
          ref="searchDiagnosisInput"
          hide-details="auto"
          :loading="loading"
          autocomplete="off"
          :rules="[rules.maxCharactersLength(120)]"
          :error="diagnosesFilter.errror"
          :error-messages="diagnosesFilter.message"
          no-filter>
          <template slot="append">
            <v-icon v-if="searching"
              class="black--text"
              @click.stop="clearSearch()">mdi-close-circle</v-icon>
            <v-icon v-else
              class="black--text">mdi-magnify</v-icon>
          </template>
          <template slot="no-data">
            <v-list-item-content class="py-2">
              <v-row class="px-4" no-gutters>
                <v-col cols="12">
                  <span class="text-body-2 grey--text text--darken-1"> {{ $t('addAuthorization.diagnoses.emptyList') }}</span>
                </v-col>
              </v-row>
            </v-list-item-content>
          </template>
          <template slot="append-item" v-if="isAllowedCustomDiagnoses">
            <v-list-item-content class="py-2 add-item primary--text">
              <v-row no-gutters class="px-4" @click="selectCustomDiagnosis" v-if="searchInput && $refs.searchDiagnosisInput.valid">
                <v-col cols="12">
                  <span class="add-button"> {{ $t('addAuthorization.diagnoses.addDiagnosisLabel', { value: searchInput }) }} </span>
                </v-col>
              </v-row>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
          <div :class="['mt-4', isMobile ? 'text-center' : 'text-right']" v-if="finderDisabled">
            <v-icon color="error" class="material-icons">error</v-icon>
            <span class="text-body-2 ml-2">{{ $t('addAuthorization.diagnoses.maximumNumberOfItemsReached') }} </span>
          </div>
      </v-col>
    </v-row>
    <v-row class="mt-4"
      v-if="value && value.length > 0">
      <v-col cols="12" class="py-1"
        v-for="(diagnosis, index) of value.filter(diagnosis => !diagnosis.customDiagnosis)"
        :key="index">
        <v-chip class="diagnosis-chip py-1 my-1 align-left text-body-2 text-wrap"
          close
          close-icon="mdi-close-circle"
          text-color="secondary"
          color="#f4f5f7"
          label
          @click:close="deleteDiagnosis(diagnosis)">
          ({{ diagnosis.code }}) {{diagnosis.name}}
        </v-chip>
      </v-col>
      <v-col cols="12" class="py-2"></v-col>
      <v-col cols="12" class="py-3"
        v-for="(diagnosis, index) of value.filter(diagnosis => diagnosis.customDiagnosis)"
        :key="`custom-${index}`" >
        <v-text-field 
            type="text"
            ref="customDiagnosis"
            :append-icon="'mdi-window-close'"
            class="diagnosis-input"
            :label="diagnosisLabel(index)"
            autocomplete="nope"
            role="presentation"
            @input="changeDiagnosis($event, index)"
            dense
            :value="diagnosis.name"
            hide-details="auto"
            :rules="[(value) => rules.maxLength(value, 120), rules.required, rules.diagnosisRepeated(diagnosis.isRepeated, diagnosisType)]"
            @click:append="deleteDiagnosis(diagnosis)"
          ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense
      v-else>
      <v-col cols="12">
        <slot name="no-data"></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MedicalCatalogService from '@/services/medical-catalogs.service';
import { inputRules } from "@/utils";

export default {
  name: 'DiagnosesFinder',
  props: {
    searchLabel: {
      type: String,
    },
    diagnosisType: {
      type: String,
      default: 'MEDICAL_DIAGNOSIS'
    },
    customDiagnosisItemListLabel: {
      type: String
    },
    maximumNumberOfItems: {
      type: Number,
      default: 10
    },
    value: {
      type: Array,
      default: () => [],
    },
    isAllowedCustomDiagnoses: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    searchInput: null,
    selectedDiagnosis: null,
    diagnoses: [],
    loading: false,
    changeCustomDiagnosis: null,
    hideNoResults: true,
    rules: inputRules,
    diagnosesFilter: {
      error: false,
      message: ''
    }
  }),
  methods: {
    selectDiagnosis() {
      const diagnosisIndex = this.value.findIndex(
        (diagnosis) => diagnosis.id === this.selectedDiagnosis.id && diagnosis.synonymId === this.selectedDiagnosis.synonymId
      );

      this.selectedDiagnosis = {
        ...this.selectedDiagnosis, 
        customDiagnosis: false,
        type: this.diagnosisType
      }

      if (diagnosisIndex === -1) {
        this.$emit('input', [...this.value, this.selectedDiagnosis]);
      }

      this.$refs.searchDiagnosisInput.reset();
      this.clearSearch();
      this.diagnoses = [];
    },
    selectCustomDiagnosis() {
      const diagnosisIndex = this.value.findIndex(
        (diagnosis) => diagnosis.name.toLowerCase() === this.searchInput.toLowerCase()
      );

      const selectedDiagnosis = {
        name: this.searchInput, 
        customDiagnosis: true,
        type: this.diagnosisType
      }

      if (diagnosisIndex === -1) {
        this.$emit('input', [...this.value, selectedDiagnosis]);
      }

      this.$refs.searchDiagnosisInput.reset();
      this.clearSearch();
      this.diagnoses = [];
    },
    deleteDiagnosis(diagnosis) {
      this.$emit(
        'input',
        this.value.filter((storedDiagnosis) => storedDiagnosis !== diagnosis)
      );
    },
    changeDiagnosis(event, index) {
      const customDiagnoses = this.value.filter(diagnosis => diagnosis.customDiagnosis);

      const isRepeatedDiagnosis = customDiagnoses.filter((item) => {
        if(item.name !== null && item.name !== '') {
          return item.name.toLowerCase() === event.toLowerCase();
        }
      }).length > 0;

      customDiagnoses[index] = {...customDiagnoses[index], name: event, isRepeated: isRepeatedDiagnosis }

      const allDiagnoses = this.value.filter(diagnosis => !diagnosis.customDiagnosis).concat(customDiagnoses)

      this.$emit('input', [...allDiagnoses]);
      
      const vm = this;
      vm.$nextTick(() => {
        vm.$refs.customDiagnosis[index].focus()
      })
    },
    clearSearch() {
      this.searchInput = null;
      this.selectedDiagnosis = null;
    },
    validate() {
      this.validated = true;
      return this.valid;
    },
    diagnosisLabel(index) {
      return `${this.customDiagnosisItemListLabel} ${index + 1}`
    },
    async fetchDiagnosesDebounced(newValue) {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout( async () => {
        try {
          const response = await MedicalCatalogService.getDiagnoses({
            name: newValue,
            tag: this.diagnosisType
          });

          this.diagnoses = response.data.content
          .filter((diagnosis, index) => index < 10)
          .map(diagnosis => ({
            id: diagnosis.diagnosis_id,
            code: diagnosis.code,
            name: diagnosis.name,
            codingSystem: diagnosis.coding_system,
            synonymId: diagnosis.diagnosis_synonym_id,
            customDiagnosis: false
          }));
          this.loading = false;
        } catch (error) {
          this.loading = false;
          return;
        }
      }, 300)
    }
  },
  computed: {
    searching() {
      return this.searchInput || this.selectedDiagnosis;
    },
    valid() {
      if (!this.validated) return true;
      return this.value.length > 0;
    },
    finderDisabled() {
      if (this.value.length >= this.maximumNumberOfItems) {
        if (this.$refs.searchDiagnosisInput) {
          this.$refs.searchDiagnosisInput.blur();
        }
        return true;
      }
      return false
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    }
  },
  watch: {
    async searchInput(newValue) {
      if (this.$refs.searchDiagnosisInput.valid) {
        this.diagnosesFilter.error = false
        this.diagnosesFilter.message = ''
      } else {
        this.diagnosesFilter.message = this.$t('errors.inputValidations.maxLength', [120])
      }
      if (newValue) {
        this.loading = true;
         this.hideNoResults = !this.$refs.searchDiagnosisInput.valid;
        this.fetchDiagnosesDebounced(newValue)
      } else {
        this.hideNoResults = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.diagnosis-chip ::v-deep .v-chip__close.v-icon { 
  color: var(--v-secondary-base);
  font-size: 1.25rem !important;
  right: 0;
}

.add-item {
  cursor: pointer;
  border-top: solid #bdbdbd 1px;
}

.add-button {
  font-size: 0.688rem;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.25px;
}
</style>