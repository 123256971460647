<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="4">
        <v-row no-gutters>
          <v-col>
            <span class="text-body-2 secondary--text font-weight-bold">
              {{ $t("authorizationDetail.information.beneficiary.name") }}
            </span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <span class="secondary--text text-body-2">
              {{ beneficiary.name }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" v-if="beneficiary.emailAddress">
        <v-row no-gutters>
          <v-col>
            <span class="text-body-2 secondary--text font-weight-bold">
              {{ $t("authorizationDetail.information.beneficiary.name") }}
            </span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <span class="secondary--text text-body-2">
              {{ beneficiary.emailAddress }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-row no-gutters>
          <v-col>
            <span class="text-body-2 secondary--text font-weight-bold">
              {{ $t("authorizationDetail.information.beneficiary.age") }}
            </span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <span class="secondary--text text-body-2">
              {{ $t('authorizationDetail.information.beneficiary.years', {years: beneficiary.age}) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-row no-gutters>
          <v-col>
            <span class="text-body-2 secondary--text font-weight-bold">
              {{
                $t("authorizationDetail.information.beneficiary.identification")
              }}
            </span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <span class="secondary--text text-body-2">
              {{ beneficiary.identityDocumentId }}
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { camelize } from "humps";

export default {
  name: "BenefiaryHeader",
  props: {
    beneficiary: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    camelize(param) {
      return camelize(param.toLocaleLowerCase());
    },
  },
};
</script>
