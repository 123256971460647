<template>
  <div>
    <Beneficiary :key="index" v-for="(item, index) in beneficiaryValues"
      :value="item"
      ref="beneficiary"
      :globalPercentage="globalPercentage"
      :index="index"
      @onDelete="onDelete(index)"
      @input="onInput($event, index)"
    >
    </Beneficiary>
  </div>
</template>

<script>
import Beneficiary from './Beneficiary.vue'

export default {
  name: "Beneficiaries",
  components: { Beneficiary },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    beneficiaryValues() {
      return this.value
    },
    globalPercentage() {
      return this.value.map(beneficiary => beneficiary.percentage ? parseFloat(beneficiary.percentage) : 0).reduce((prev, curr) => prev + curr, 0)
    }
  },
  methods: {
    onDelete(index) {
      this.$emit('input',
        this.value.filter((beneficiary, idx) => idx !== index)
      )
    },
    onInput(event, index) {
      const changeValue = [...this.value]
      changeValue[index] = event
      this.$emit('input', changeValue)
    },
    validate() {
      let valid = true;
      this.$refs.beneficiary && 
        this.$refs.beneficiary.forEach(field => {
          const validation = field.validate();
          if (valid) {
            valid = validation
          }
        })
      return this.value.length > 0 && valid
    },
  }
}
</script>