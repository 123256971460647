<template>
  <v-col class="pa-0">
    <v-row>
      <v-col class="col-12 pb-0">
        <div v-if="!!$slots.title">
          <slot name="title"></slot>
        </div>
        <span v-else class="text-subtitle-1 osg-black--text">{{
          field.label
        }}</span>
      </v-col>

      <v-col cols="12" :class="{ 'py-0': !field.required && field.label }">
        <span class="text-overline" v-if="!field.required && field.label">
          {{ $t("addAuthorization.fields.optional") }}
        </span>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="field.description">
      <v-col class="pb-1">
        <span class="text-body-2 osg-bloack--text">{{
          field.description
        }}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-chip-group
          ref="fieldValidationForm"
          active-class="primary--text"
          :rules="fieldRules"
          hide-details="auto"
          column
          v-model="form"
          @change="onSelectedValue($event)"
        >
          <v-row justify="center">
            <v-col
              :cols="$vuetify.breakpoint.mdAndUp ? 3 : 12"
              class="py-0 d-flex flex-column"
              v-if="wellnessCoverageTypeAuthorizations.length > 0"
            >
              <span class="text-body-2 ml-2" style="color: #4f4f4f;">
                {{ $t("addAuthorization.fields.wellnessCoverageTypeLabel") }}
              </span>
              <v-chip
                v-for="option in wellnessCoverageTypeAuthorizations"
                :key="option.key"
                :value="option"
                color="grey lighten-4"
                style="font-size: 0.75rem"
                class="pa-3 mb-3 osigu-chip-field"
              >
                {{ option.label }}
                <v-avatar right v-if="option.key === chipSelected">
                  <v-icon size="18" color="secondary"
                    >mdi-checkbox-marked-circle</v-icon
                  >
                </v-avatar>
              </v-chip>
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.mdAndUp ? 3 : 12"
              class="py-0 d-flex flex-column"
              v-if="otherCoverageTypeAuthorizations.length > 0"
            >
              <span class="text-body-2 ml-2" style="color: #4f4f4f;">
                {{ $t("addAuthorization.fields.othersCoverageTypeLabel") }}
              </span>
              <v-chip
                v-for="option in otherCoverageTypeAuthorizations"
                :key="option.key"
                :value="option"
                color="grey lighten-4"
                style="font-size: 0.75rem"
                class="pa-3 mb-3 osigu-chip-field text-wrap"
              >
                <div
                  class="d-flex flex-row justify-space-between"
                  style="width: 95%"
                >
                  {{ option.label }}
                  <v-icon
                    v-if="option.key === chipSelected"
                    size="18"
                    color="secondary"
                    >mdi-checkbox-marked-circle</v-icon
                  >
                </div>
              </v-chip>
            </v-col>
          </v-row>
        </v-chip-group>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { inputRules } from "@/utils";
import { createNamespacedHelpers } from "vuex";
const authorizationModule = createNamespacedHelpers("authorizationV2");

export default {
  name: "SelectField",
  props: {
    field: {
      type: Object,
      default: () => ({
        slug: null,
        type: null,
        label: null,
        showOrder: null,
        required: null,
        description: null,
        groupName: null,
        mimeTypes: null,
        fileLimit: null,
        options: null,
        prefix: null,
        suffix: null,
        placeholder: null,
        decimalPrecision: null,
        groupOrder: null,
        cssClass: "col-12",
        min: null,
        max: null,
        defaultValue: null,
      }),
    },
  },
  data() {
    return {
      rules: inputRules,
      chipSelected: null,
    };
  },
  methods: {
    ...authorizationModule.mapActions([
      'setError',
      "setCurrentCoverage",
      "setCurrentForm",
      "resetAuthorizationState",
    ]),
    validate() {
      if (this.form === null) {
        this.setError({
          hasError: true,
          error: this.$i18n.t(
            "errors.inputValidations.authorizationTypeRequired"
          ),
        });
        return false;
      }
      return true;
    },
    onSelectedValue(event) {
      if(this.validSelectedValue(event)){
        this.chipSelected = event.key;
      }
    },
    validSelectedValue(value){
      return value && value.key;
    }
  },
  computed: {
    ...authorizationModule.mapGetters(["currentCoverage"]),
    form: {
      get() {
        return this.currentCoverage;
      },
      set(value) {
        if(this.validSelectedValue(value)){
          this.resetAuthorizationState();        
          this.setCurrentCoverage(value);
          this.setCurrentForm(value.key);
        }
      },
    },
    fieldRules() {
      const fieldRulesArray = [];
      if (this.field.required) {
        fieldRulesArray.push(this.rules.required);
      }

      return fieldRulesArray;
    },
    wellnessCoverageTypeAuthorizations() {
      const items = this.field.options;
      return items.filter((item) => item.coverageType === "WELLNESS");
    },
    otherCoverageTypeAuthorizations() {
      const items = this.field.options;
      return items.filter((item) => item.coverageType != "WELLNESS");
    },
  },
};
</script>

<style lang="scss" scoped>
.osigu-chip-field ::v-deep .v-chip__content {
  font-size: 0.75rem;
  width: 100%;
  height: 57px;
}

.osigu-chip-field.v-chip.v-size--default {
  height: 60px !important;
  width: 100%;
}

.osigu-chip-field ::v-deep .v-avatar {
  padding-right: 16px;
  width: 100% !important;
}

.osigu-chip-field ::v-deep .v-icon {
  justify-content: flex-end;
}

.v-text-field ::v-deep .v-messages,
.v-select ::v-deep .v-messages {
  color: #7a7a7a;
}

@media (min-width: 600px) {
  .osg-textfield-offset-sm-6-right {
    margin-right: 50% !important;
  }
}
</style>
