<template>
  <v-col class="pa-0">
    <v-row>
      <v-col class="col-8 pb-0">
        <div v-if="!!$slots.title">
          <slot name="title"></slot>
        </div>
        <span v-else class="text-subtitle-1 osg-black--text">{{
          field.label
        }}</span>
      </v-col>
      <v-col class="pb-0 text-right col-4">
        <v-btn depressed small rounded @click="onAddBeneficiary()">
          <v-icon small color="secondary">mdi-plus</v-icon>
          <span class="text-button secondary--text">
            {{ $t("addAuthorization.buttons.add") }}
          </span>
        </v-btn>
      </v-col>
      <v-col cols="12" :class="{ 'py-0': !field.required && field.label }">
        <span class="text-overline" v-if="!field.required && field.label">
          {{ $t("addAuthorization.fields.optional") }}
        </span>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="field.description">
      <v-col class="pb-1">
        <span class="text-body-2 osg-bloack--text">{{
          field.description
        }}</span>
      </v-col>
    </v-row>

    <Beneficiaries
      ref="fieldValidationForm"
      :value="recoverFieldArrayValue"
      @input="changeInput"
    ></Beneficiaries>
  </v-col>
</template>

<script>
import Beneficiaries from "../custom-fields/beneficiary/Beneficiaries.vue";

import { createNamespacedHelpers } from "vuex";
const authorizationModule = createNamespacedHelpers("authorizationV2");

export default {
  name: "BeneficiaryField",
  components: {
    Beneficiaries,
  },
  props: {
    field: {
      type: Object,
      default: () => ({
        slug: null,
        type: null,
        label: null,
        showOrder: null,
        required: null,
        description: null,
        groupName: null,
        mimeTypes: null,
        fileLimit: null,
        options: null,
        prefix: null,
        suffix: null,
        placeholder: null,
        decimalPrecision: null,
        groupOrder: null,
        cssClass: "col-12",
        min: null,
        max: null,
        defaultValue: null,
      }),
    },
  },
  mounted() {
    this.onAddBeneficiary();
  },
  methods: {
    validate() {
      return this.$refs.fieldValidationForm.validate();
    },
    onAddBeneficiary() {
      let value = [];
      const beneficiary = {};
      const inputField = this.inputs.find(
        (input) => input.slug === this.field.slug
      );
      if (inputField) {
        const inputValue = JSON.parse(inputField.value);
        if (inputValue.length !== 0) {
          if (Object.keys(inputValue[inputValue.length - 1]).length === 0) {
            return;
          }
        }
        value = [...inputValue, beneficiary];
      } else {
        value = [...value, beneficiary];
      }
      this.$emit("input", value);
    },
    changeInput(e) {
      this.$emit("input", e);
    },
  },
  computed: {
    ...authorizationModule.mapGetters(["inputs"]),
    recoverFieldArrayValue() {
      const inputField = this.inputs.find(
        (input) => input.slug === this.field.slug
      );
      return (inputField && JSON.parse(inputField.value)) || [];
    },
  },
};
</script>
