<template>
    <v-row>
        <v-col v-if="beneficiaries.length > 0" class="scroll-container">
            <v-row>
                <v-col>
                    <p class="text-body-1 font-weight-bold osg-gray-1--text">
                        {{ $t('addAuthorization.searchBeneficiary.results', { total: beneficiaries.length }) }}
                    </p>
                    <p class="text-body-3 osg-gray-1--text">
                        {{$t('addAuthorization.searchBeneficiary.selectMessage')}}
                    </p>
                </v-col>
            </v-row>
            <v-list>
                <v-list-item-group v-model="beneficiarySelected" @change="searchSumAssured">
                    <v-list-item
                        class="rounded-card"
                        active-class="rounded-card-active"
                        v-for="(beneficiary) in beneficiaries" :key="beneficiary.id"
                        :value="beneficiary"
                    >
                        <template #default={active}>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <BeneficiaryHeader :beneficiary="beneficiary" :active="active" />
                                </v-list-item-title>

                                <v-list-item-subtitle>
                                    <BeneficiaryInformation
                                        :beneficiary="beneficiary"
                                        :active="active"
                                        :availableAnnualBenefit="getAvailableAnnualBenefit"
                                        :status="getBeneficiaryStatus"
                                    />
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-col>
        <v-col v-if="beneficiaries.length === 0 && beneficiarySearchCompleted">
            <v-row justify="center" align="center" dense no-gutters>
                <v-col class="d-flex flex-column align-center justify-center" cols="auto">
                    <EmptyStateIcon />
                    <p class="text-h5 secondary--text">
                        {{ $t('addAuthorization.searchBeneficiary.noResultsTitle') }}
                    </p>

                    <p class="caption mb-0">
                        {{ $t('addAuthorization.searchBeneficiary.noResultsDescription') }}
                    </p>

                    <p class="caption">
                        {{ $t('addAuthorization.searchBeneficiary.noResultsDescription2') }}

                        <span class="primary--text font-weight-bold cursor-pointer" @click="resetSearch">
                            {{ $t('addAuthorization.searchBeneficiary.newSearch') }}
                        </span>
                    </p>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import EmptyStateIcon from '@/components/svg/EmptyStateIcon.vue'
import BeneficiaryHeader from './results-items/BeneficiaryHeader.vue'
import BeneficiaryInformation from './results-items/BeneficiaryInformation.vue'

export default {
    name: 'BeneficiaryResults',

    components: {
        EmptyStateIcon,
        BeneficiaryHeader,
        BeneficiaryInformation
    },

    data() {
        return {
            beneficiarySelected: null
        }
    },

    props: {
        beneficiaries: {
            type: Array,
            required: true
        },

        beneficiaryInformation: {
            type: Object,
            default: () => null
        },

        beneficiarySearchCompleted: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        resetSearch() {
            this.$emit('reset-search')
        },

        searchSumAssured(){
            if(this.beneficiarySelected && this.beneficiarySelected.id){
                this.$emit('select-beneficiary', this.beneficiarySelected)
            }else{
                this.$emit('select-beneficiary', null)
            }
        }
    },

    computed: {
        getAvailableAnnualBenefit() {
          return this.beneficiaryInformation?.generalBalance?.maximumBenefitAvailable
        },

        getBeneficiaryStatus() {
          return this.beneficiaryInformation?.status
        }
    }
}
</script>
<style scoped>
    .cursor-pointer {
        cursor: pointer;
    }

    .rounded-card {
        border: 1px solid var(--v-osg-gray-2-base);
        border-radius: 20px;
        margin-bottom: 15px;
    }

    .rounded-card-active {
        border: 1px solid var(--v-primary-base);
        background-color: white !important;
    }

    .v-list-item--link:before {
        background-color: transparent !important;
    }

    .scroll-container {
        max-height: 60vh;
        overflow-y: auto;
    }
</style>
