<template>
  <v-form lazy-validation ref="sponsorForm" class="mb-5" @submit.prevent="searchBeneficiary">
    <v-row>
      <v-col>
        <v-radio-group row v-model="searchMethod" hide-details="auto" @change="reset">
          <v-radio
            v-for="form in sponsorForms"
            :key="form.slug"
            :label="form.name"
            :value="form"
            color="primary"
            ripple
          >
            <template slot="label">
              <span class="text-subtitle-2 pt-1">{{ form.name }}</span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="searchMethod">
      <v-col cols="12">
        <v-row v-for="input in searchMethod.fields" :key="`row-${input.slug}`">
          <v-col cols="12">
            <v-text-field
              v-if="input.type === 'string'"
              dense
              outlined
              type="text"
              class="rounded"
              hide-details="auto"
              v-model="inputValues[input.slug]"
              :placeholder="input.name"
              :label="input.name"
              :ref="'input-' + input.slug"
              :rules="inputRules(input)"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="searchMethod">
      <v-col>
        <v-btn
          block
          class="rounded"
          color="primary"
          :disabled="!enableSearch"
          @click="searchBeneficiary"
        >
          {{ $t('addAuthorization.searchBeneficiary.searchBeneficiary') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { inputRules } from '@/utils'

export default {
  name: 'SearchBeneficiarySponsorForm',

  props: {
    sponsorForms: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      searchMethod: null,
      inputValues: {},
      enableSearch: false
    }
  },

  methods: {
    inputRules(input) {
      const rules = []

      const { required, pattern, maxLength, minLength } = input

      if (required) {
        rules.push(inputRules.required)
      }

      if (pattern) {
        const patternRule = (value) => inputRules.pattern(value, pattern)
        rules.push(patternRule)
      }

      if (maxLength) {
        const maxLengthRule = (value) => inputRules.maxLength(value, maxLength)
        rules.push(maxLengthRule)
      }

      if (minLength) {
        const minLengthRule = (value) => inputRules.minLength(value, minLength)
        rules.push(minLengthRule)
      }

      return rules
    },

    reset() {
      this.enableSearch = false
      this.inputValues = {}
    },

    resetValues() {
      const inputSlugs = this.searchMethod?.fields.map(field => field.slug)

      if (!inputSlugs) return

      inputSlugs.forEach(slug => {
        const ref = this.$refs[`input-${slug}`]
        if (ref) {
          ref[0].reset()
        }
      })
    },

    validate() {
      return this.$refs.sponsorForm.validate()
    },

    searchBeneficiary() {
      if (this.validate()) {
        const inputs = Object.keys(this.inputValues).map(key => ({
          slug: key,
          value: this.inputValues[key]
        }))

        this.$emit('search', {
          formSlug: this.searchMethod.slug,
          inputs: inputs
        })
      }
    }
  },

  watch: {
    inputValues: {
      deep: true,
      immediate: true,
      handler() {
        const inputSlugs = this.searchMethod?.fields.map(field => field.slug)

        if (!inputSlugs) return

        inputSlugs.forEach(slug => {
          const ref = this.$refs[`input-${slug}`]
          this.enableSearch = ref && ref[0].validate()
        })
      }
    }
  }
}
</script>
