<template>
  <v-row>
    <v-col cols="10" md="5">
      <v-row dense>
        <v-col>
          <p class="secondary--text subtitle-1 mb-3">{{ $t('addAuthorization.searchBeneficiary.title') }}</p>
          <span class="body-2">{{ $t('addAuthorization.searchBeneficiary.description') }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select
            v-model="selectedSponsor"
            dense
            outlined
            class="rounded"
            :label="$t('addAuthorization.searchBeneficiary.sponsor')"
            hide-details="auto"
            background-color="white"
            :items="sponsorItems"
            @change="onSponsorChange"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row v-if="searchBeneficiarySponsorForms.length > 0">
        <v-col>
          <SearchBeneficiarySponsorForm
            ref="sponsorForm"
            :sponsorForms="searchBeneficiarySponsorForms"
            @search="searchBeneficiary"
          />
        </v-col>
      </v-row>
    </v-col>

    <v-col md="6" xl="4" offset-md="1" offset-xl="2" class="pl-5">
      <BeneficiaryResults
        :beneficiaries="searchBeneficiaryResults"
        :beneficiary-information="beneficiaryInformation"
        :beneficiary-search-completed="beneficiarySearchCompleted"
        @reset-search="resetSearch"
        @select-beneficiary="selectBeneficiary"
      />
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import BeneficiaryResults from './BeneficiaryResults.vue'
import SearchBeneficiarySponsorForm from './SearchBeneficiarySponsorForm.vue'

const authModule = createNamespacedHelpers('auth')
const authorizationModule = createNamespacedHelpers('authorizationV2')
const generalModule = createNamespacedHelpers('general')


export default {
  name: 'SearchBeneficiaryExternalBenefits',

  components: {
    SearchBeneficiarySponsorForm,
    BeneficiaryResults
  },

  data() {
    return {
      selectedSponsor: null
    }
  },

  methods: {
    ...generalModule.mapActions(['showLoader']),
    ...authModule.mapActions(['requestClientToken']),

    ...authorizationModule.mapActions([
      'getBeneficiarySponsorForms',
      'searchBeneficiaries',
      'searchBeneficiaryBenefits',
      'setSelectedBeneficiary',
      'setBeneficiarySearchCompleted',
      'getBeneficiary',
      'setSponsorConfiguration'
    ]),

    async onSponsorChange() {
      await this.requestClientToken()
      await this.getBeneficiarySponsorForms(this.selectedSponsor)
      await this.setSponsorConfiguration(this.selectedSponsor)
    },

    async searchBeneficiary(payload){
      this.showLoader(true)
      this.setSelectedBeneficiary(null)
      await this.requestClientToken()
      await this.searchBeneficiaries({sponsorSlug: this.selectedSponsor, payload})
      this.setBeneficiarySearchCompleted(true)
      this.showLoader(false)
    },

    resetSearch() {
      this.$refs.sponsorForm.resetValues()
    },

    async selectBeneficiary(beneficiary) {
      if(!beneficiary) {
        this.setSelectedBeneficiary(null)
        this.$emit('input', null)
        return
      }

      const beneficiaryId = beneficiary.id
      this.searchBeneficiaryBenefits(beneficiaryId)
      this.setSelectedBeneficiary(beneficiary)
      this.$emit('input', beneficiary)
    },

    validate() {
      return this.selectedSponsor && this.selectedBeneficiary
    },

    customValidations() {
      if(this.selectedSponsor && this.selectedBeneficiary){
        const { generalBalance } = this.beneficiaryInformation
        const { minimumBenefitRequired } = this.sponsorConfiguration

        if(generalBalance.maximumBenefitAvailable && minimumBenefitRequired){
          if(generalBalance.maximumBenefitAvailable <= minimumBenefitRequired){
            return {
              error: true,
              message: 'El beneficiario no cuenta con suma asegurada disponible para realizar la autorización'
            }
          }
        }

        if(this.beneficiaryInformation.status !== 'ENABLED'){
          return {
            error: true,
            message: 'El beneficiario no se encuentra activo'
          }
        }

      }

      return { error: false, message: '' }
    }
  },

  computed: {
    ...authorizationModule.mapGetters([
      'currentStep',
      'searchBeneficiarySponsorForms',
      'searchBeneficiaryResults',
      'beneficiaryInformation',
      'selectedBeneficiary',
      'beneficiarySearchCompleted',
      'sponsorConfiguration'
    ]),

    sponsorItems() {
      return [
        {text: 'Metlife', value: "mx-mutuus" }
      ]
    }

  }
}
</script>
