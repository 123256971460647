<template>
  <v-row dense>
    <v-col cols="11">
      <v-row dense>
        <v-col cols="6" class="osg-gray-3--text">
          <span class="text-body-3">
            {{ $t('addAuthorization.searchBeneficiary.birthdate') }}

            <span class="font-weight-bold ml-1">
              {{ parseDate(beneficiary.dateOfBirth) }}
            </span>
          </span>
        </v-col>
        <v-col cols="6" class="osg-gray-3--text">
          <span class="text-body-3">
            {{ $t('addAuthorization.searchBeneficiary.age') }}

            <span class="font-weight-bold ml-1"> {{ beneficiary.age }} años </span>
          </span>
        </v-col>
        <v-col cols="6" class="osg-gray-3--text">
          <span class="text-body-3">
            {{ $t('addAuthorization.searchBeneficiary.Identification') }}

            <span class="font-weight-bold ml-1">
              {{ beneficiary.identityDocumentId }}
            </span>
          </span>
        </v-col>
        <v-col cols="6" class="osg-gray-3--text">
          <span class="text-body-3">
            {{ $t('addAuthorization.searchBeneficiary.Sex') }}

            <span class="font-weight-bold ml-1">
              {{ $t('addAuthorization.searchBeneficiary.' + beneficiary.sex) }}
            </span>
          </span>
        </v-col>
      </v-row>
      <v-row dense v-if="active" class="mt-3">
        <v-col cols="6">
          <span class="text-body-3 primary--text font-weight-bold">
            {{ $t('addAuthorization.searchBeneficiary.availableAnnualBenefit') }}
            <span
              :class="['font-weight-bold secondary--text ml-1', getAvailableAnnualBenefit.color]"
            >
              {{ getAvailableAnnualBenefit.value }}
            </span>
          </span>
        </v-col>
        <v-col cols="6">
          <span class="text-body-3 primary--text font-weight-bold">
            {{ $t('addAuthorization.searchBeneficiary.status') }}
            <span class="font-weight-bold secondary--text ml-1"> {{ getStatus }} </span>
          </span>
        </v-col>
      </v-row>
    </v-col>

    <v-spacer></v-spacer>

    <v-col cols="1" v-if="active" align-self="center">
      <v-icon color="primary">check_circle</v-icon>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import { createNamespacedHelpers } from 'vuex'

const authModule = createNamespacedHelpers('auth')

export default {
  name: 'BeneficiaryInformation',

  props: {
    beneficiary: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    availableAnnualBenefit: {
      type: Number,
      default: null
    },
    status: {
      type: String,
      default: null
    }
  },

  computed: {
    ...authModule.mapGetters(['tokenExtensions']),

    locale() {
      if (this.tokenExtensions && this.tokenExtensions.locale) {
        return this.tokenExtensions.locale.replace('_', '-')
      }

      return ''
    },

    getAvailableAnnualBenefit() {
      if (!this.availableAnnualBenefit && this.availableAnnualBenefit !== 0) {
        return {
          color: 'secondary--text',
          value: '--'
        }
      }

      const result = {
        color: 'error--text',
        value: this.currencyFormatter().format(this.availableAnnualBenefit)
      }

      if (this.availableAnnualBenefit > 0) {
        result.color = 'secondary--text'
        return result
      }

      return result
    },

    getStatus() {
      if (!this.status) {
        return '--'
      }

      return this.$t('addAuthorization.searchBeneficiary.' + this.status)
    }
  },

  methods: {
    parseDate(date) {
      return date ? moment(date).utc().format('DD/MM/YYYY') : ''
    },

    currencyFormatter() {
      return new Intl.NumberFormat(this.locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    }
  }
}
</script>
